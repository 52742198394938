import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Box from "@mui/material/Box";

function ConfirmationBox({ setConfirmPopUp, openbox, message, isPurchaseFailed }) {
  const [open, setOpen] = useState(openbox);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(
    (props) => {
      setOpen(openbox);
    },
    [openbox, setOpen]
  );

  const handleClose = () => {
    setOpen(false);
    setConfirmPopUp();
    // setpopup();
  };

  return (
    <div>
      {!isPurchaseFailed && (
        <Dialog
          maxWidth="md"
          open={open}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scrollable-dialog-title">
          <DialogTitle
            id="scrollable-dialog-title"
            sx={{ textAlign: "center", fontWeight: "bold", color: "red" }}>
            Confirmation
          </DialogTitle>
          <DialogContent dividers={true}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "15px",
              }}
              variant="h6">
              Your request to buy Policy has been accepted and is under process.
              We will notify you once Purchase is complete
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isPurchaseFailed && (
        <Dialog
          maxWidth="md"
          open={open}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scrollable-dialog-title">
          <DialogTitle
            id="scrollable-dialog-title"
            sx={{ textAlign: "center", fontWeight: "bold"}}>
            Error
          </DialogTitle>
          <DialogContent dividers={true}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "15px",
                color: "red",
              }}
              variant="h6">
              We are experiencing trouble in placing your purchase request at
              this time. Please try again later!
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default ConfirmationBox;
