import "./App.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useCallback, useEffect, useState } from "react";
import { Container, Button, Typography } from "@mui/material";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Box from "@mui/material/Box";
import JsonRpcClient from "./RPCClient/RpcClient1";
import MediaCard from "./Components/Card";
import BasicModal from "./Components/Modal/Modal";
import CofirmationBox from "./Components/Modal/ConfirmationBox";
import CategoryForm from "./Components/CategoryForm";
import Quote from "./Components/quote";
import { isValid } from "date-fns";

function App() {
  const apiPayload = {
    id: 1,
    method: "purchasePolicy",
    params: [
      {
        baseData: {
          productCode: "PersonalAuto",
          policyAddress: {
            state: "IN",
            country: "US",
            addressLine1: "11513 Woodview East Dr",
            addressType: "home",
            city: "Carmel",
            postalCode: "46032",
          },
          periodStartDate: {
            year: "2024",
            month: "09",
            day: "12",
          },
          accountHolder: {
            subtype: "Person",
            accountHolder: true,
            firstName: "Joel",
            lastName: "Philipwa",
            primaryAddress: {
              addressLine1: "11513 Woodview East Dr",
              city: "Carmel",
              state: "IN",
              country: "US",
              postalCode: "46032",
              addressType: "home",
            },
            emailAddress1: "test@test.com",
            cellNumber: "2051110234",
            primaryPhoneType: "mobile",
            additionalAddresses: [],
            licenseNumber: "8549653201",
            licenseState: "IN",
            maritalStatus: "S",
          },
          termType: "HalfYear",
        },
        lobData: {
          personalAuto: {
            offerings: [
              {
                coverages: {
                  lineCoverages: [
                    {
                      name: "Liability - Bodily Injury and Property Damage",
                      description:
                        "Liability - Bodily Injury and Property Damage",
                      selected: true,
                      required: true,
                      publicID: "PALiabilityCov",
                      terms: [
                        {
                          name: "Auto Liability Package",
                          type: "PackagePALiabilityType",
                          options: [
                            {
                              name: "25/50/25",
                              code: "pack_179",
                              optionValue: 1,
                              maxValue: 5,
                            },
                            {
                              name: "50/100/50",
                              code: "pack_182",
                              optionValue: 1,
                              maxValue: 5,
                            },
                            {
                              name: "100/200/50",
                              code: "pack_184",
                              optionValue: 2,
                              maxValue: 5,
                            },
                            {
                              name: "250/500/100",
                              code: "pack_185",
                              optionValue: 2,
                              maxValue: 5,
                            },
                            {
                              name: "50K CSL",
                              code: "pack_187",
                              optionValue: 3,
                              maxValue: 5,
                            },
                            {
                              name: "100K CSL",
                              code: "pack_188",
                              optionValue: 3,
                              maxValue: 5,
                            },
                            {
                              name: "250K CSL",
                              code: "pack_189",
                              optionValue: 4,
                              maxValue: 5,
                            },
                            {
                              name: "500K CSL",
                              code: "pack_190",
                              optionValue: 4,
                              maxValue: 5,
                            },
                            {
                              name: "1M CSL",
                              code: "pack_191",
                              optionValue: 5,
                              maxValue: 5,
                            },
                          ],
                          patternCode: "PALiability",
                          required: true,
                          publicID: "PALiability",
                          isAscendingBetter: true,
                          coveragePublicID: "PALiabilityCov",
                          updated: true,
                          chosenTerm: "pack_190",
                          chosenTermValue: "500K CSL",
                        },
                      ],
                      coverageCategoryCode: "PAPLiabGrp",
                      coverageCategoryDisplayName:
                        "Personal Auto Liability Group",
                      updated: true,
                    },
                    {
                      name: "Medical Payments",
                      description: "Medical Payments",
                      selected: true,
                      publicID: "PAMedPayCov",
                      terms: [
                        {
                          name: "Medical Limit",
                          type: "OptionPAMedLimitType",
                          required: true,
                          options: [
                            {
                              name: "5,000",
                              maxValue: 5,
                              optionValue: 1,
                              code: "opt_309",
                            },
                            {
                              name: "10,000",
                              maxValue: 5,
                              optionValue: 2,
                              code: "opt_310",
                            },
                            {
                              name: "15,000",
                              maxValue: 5,
                              optionValue: 3,
                              code: "opt_311",
                            },
                            {
                              name: "20,000",
                              maxValue: 5,
                              optionValue: 4,
                              code: "opt_312",
                            },
                            {
                              name: "25,000",
                              maxValue: 5,
                              optionValue: 5,
                              code: "opt_313",
                            },
                          ],
                          publicID: "PAMedLimit",
                          patternCode: "PAMedLimit",
                          coveragePublicID: "PAMedPayCov",
                          updated: true,
                          isAscendingBetter: true,
                          chosenTerm: "opt_313",
                          selectedValue: "7500",
                        },
                      ],
                      coverageCategoryDisplayName:
                        "Personal Auto Liability Group",
                      coverageCategoryCode: "PAPLiabGrp",
                      hasTerms: true,
                    },
                    {
                      name: "Uninsured Motorist - Bodily Injury",
                      selected: true,
                      description: "Uninsured Motorist - Bodily Injury",
                      publicID: "PAUMBICov",
                      isValid: true,
                      terms: [
                        {
                          name: "Uninsured Motorist - BI Limits",
                          type: "PackagePAUMBIType",
                          required: true,
                          options: [
                            {
                              name: "25/50",
                              maxValue: 5,
                              optionValue: 1,
                              code: "pack_209",
                            },
                            {
                              name: "250/500",
                              maxValue: 5,
                              optionValue: 3,
                              code: "pack_212",
                            },
                            {
                              name: "100K CSL",
                              maxValue: 5,
                              optionValue: 4,
                              code: "pack_202",
                            },
                            {
                              name: "250K CSL",
                              maxValue: 5,
                              optionValue: 5,
                              code: "pack_203",
                            },
                          ],
                          publicID: "PAUMBI",
                          patternCode: "PAUMBI",
                          updated: true,
                          coveragePublicID: "PAUMBICov",
                          isAscendingBetter: true,
                          chosenTerm: "pack_209",
                          chosenTermValue: "25/50",
                        },
                      ],
                      coverageCategoryDisplayName:
                        "Personal Auto Liability Group",
                      coverageCategoryCode: "PAPLiabGrp",
                      hasTerms: true,
                    },
                    {
                      name: "Underinsured Motorist - Bodily Injury",
                      selected: true,
                      description: "Underinsured Motorist - Bodily Injury",
                      publicID: "PAUIMBICov",
                      isValid: true,
                      terms: [
                        {
                          name: "Underinsured Motorist BI Limits",
                          type: "PackagePAUIMBIType",
                          required: true,
                          options: [
                            {
                              name: "25/50",
                              maxValue: 5,
                              optionValue: 1,
                              code: "pack_254",
                            },
                            {
                              name: "50/100",
                              maxValue: 5,
                              optionValue: 3,
                              code: "pack_256",
                            },
                            {
                              name: "100/300",
                              maxValue: 5,
                              optionValue: 4,
                              code: "pack_258",
                            },
                            {
                              name: "250/500",
                              maxValue: 5,
                              optionValue: 5,
                              code: "pack_260",
                            },
                          ],
                          publicID: "PAUIMBI",
                          patternCode: "PAUIMBI",
                          updated: true,
                          coveragePublicID: "PAUIMBICov",
                          isAscendingBetter: true,
                          chosenTerm: "pack_254",
                          chosenTermValue: "25/50",
                        },
                      ],
                      coverageCategoryDisplayName:
                        "Personal Auto Liability Group",
                      coverageCategoryCode: "PAPLiabGrp",
                      hasTerms: true,
                    },
                    {
                      name: "Underinsured Motorist - Property Damage",
                      selected: true,
                      description: "Underinsured Motorist - Property Damage",
                      publicID: "PAUIMPDCov",
                      isValid: true,
                      terms: [
                        {
                          name: "Underinsured Motorist - Property Damage Limit",
                          type: "OptionPAUIMPDlimitType",
                          required: true,
                          options: [
                            {
                              name: "$25,000",
                              maxValue: 5,
                              optionValue: 2,
                              code: "z78isngduqsou6dhe4b68k7hpg8",
                            },
                            {
                              name: "$50,000",
                              maxValue: 5,
                              optionValue: 3,
                              code: "zn2juqgsb6ljo275tl4j69m0dka",
                            },
                            {
                              name: "$100,000",
                              maxValue: 5,
                              optionValue: 5,
                              code: "zsahc20k8vbo47iftogik7as63a",
                            },
                          ],
                          publicID: "PAUIMPDlimit",
                          patternCode: "PAUIMPDlimit",
                          updated: true,
                          coveragePublicID: "PAUIMPDCov",
                          isAscendingBetter: true,
                          chosenTerm: "z78isngduqsou6dhe4b68k7hpg8",
                          selectedValue: "$34000",
                        },
                      ],
                      coverageCategoryDisplayName:
                        "Personal Auto Liability Group",
                      coverageCategoryCode: "PAPLiabGrp",
                      hasTerms: true,
                    },
                    {
                      name: "Mexico Coverage - Limited",
                      selected: true,
                      description: "Mexico Coverage - Limited",
                      publicID: "PALimitedMexicoCov",
                      isValid: true,
                      terms: [],
                      coverageCategoryDisplayName:
                        "Personal Auto Liability Group",
                      coverageCategoryCode: "PAPLiabGrp",
                      hasTerms: false,
                    },
                  ],
                },
                branchCode: "CUSTOM",
                branchName: "CUSTOM",
              },
            ],
            preQualQuestionSets: [
              {
                code: "PAPersonalAutoPreQual",
                answers: {
                  PriorDeclinedPolicy: "false",
                  PACurrentlyInsured: null,
                  MovingViolations2: "false",
                  DriverNameConviction: null,
                  CurrentSuspense: "false",
                  Suspense: "false",
                },
              },
            ],
            coverables: {
              addInterestTypeCategory: "PAVhcleAddlInterest",
              vehicles: [
                {
                  license: "IN-123-42",
                  displayName: "Chevrolet Malibu 2004",
                  year: 2024,
                  model: "Malibu",
                  licenseState: "IN",
                  primaryUse: "commuting",
                  annualMileage: 10000,
                  commutingMiles: 25,
                  costNew: {
                    currency: "usd",
                    amount: 15000.0,
                  },
                  vin: "1234",
                  leaseOrRent: false,
                  make: "Chevrolet",
                  additionalInterests: [],
                },
              ],
              drivers: [
                {
                  yearLicensed: 2000,
                  licenseNumber: "8549653201",
                  licenseState: "IN",
                  dateOfBirth: {
                    year: 1960,
                    month: 8,
                    day: 1,
                  },
                  person: {
                    displayName: "Joel Philip",
                    lastName: "Philip",
                    maritalStatus: "S",
                    dateOfBirth: {
                      year: 1960,
                      month: 8,
                      day: 1,
                    },
                    firstName: "Joel",
                    primaryPhoneType: "mobile",
                    cellNumber: "2051110234",
                  },
                  isPolicyHolder: true,
                  violations: "0",
                  accidents: "0",
                },
              ],
            },
          },
        },
      },
    ],
    jsonrpc: "2.0",
  };

  const [isFormValid, setIsFormValid] = useState(true);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [showZip, setshowZip] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [payload, setPayload] = useState(apiPayload);
  const [Premium, setPremium] = useState("");
  const [quoteID, setQuoteID] = useState("");
  const [medicalPayment, setMedicalPayment] = useState("");
  const [uMID, setUIMD] = useState("");
  const [isPurchaseFailed, setIsPurchaseFailed] = useState(false);
  const [quoteKey, setQuoteKey] = useState("");
  const [tempPayload, setTempPayload] = useState({
    account: {
      firstName: " ",
      lastName: " ",
      periodStartDate: { year: " ", month: " ", day: " " },
      sSN: " ",
      city: " ",
      state: " ",
      postalCode: " ",
      addressLine1: " ",
    },
    driver: {
      firstName: " ",
      lastName: " ",
      licenseNumber: " ",
    },
    coverage: {
      medicalPayment: " ",
      uMID: " ",
    },
  });

  const updateJson = () => {
    payload.params[0].baseData.accountHolder.licenseNumber =
      tempPayload.driver.licenseNumber;
    payload.params[0].baseData.accountHolder.firstName =
      tempPayload.account.firstName;
    payload.params[0].baseData.accountHolder.lastName =
      tempPayload.account.lastName;
    payload.params[0].baseData.accountHolder.primaryAddress.addressLine1 =
      tempPayload.account.addressLine1;
    payload.params[0].baseData.accountHolder.primaryAddress.city =
      tempPayload.account.city;
    payload.params[0].baseData.accountHolder.primaryAddress.postalCode =
      tempPayload.account.postalCode;
    payload.params[0].baseData.accountHolder.primaryAddress.state =
      tempPayload.account.state;
    payload.params[0].baseData.periodStartDate.month =
      tempPayload.account.periodStartDate.month;
    payload.params[0].baseData.periodStartDate.day =
      tempPayload.account.periodStartDate.day;
    payload.params[0].baseData.periodStartDate.year =
      tempPayload.account.periodStartDate.year;
    payload.params[0].lobData.personalAuto.coverables.drivers[0].person.firstName =
      tempPayload.driver.firstName;
    payload.params[0].lobData.personalAuto.coverables.drivers[0].person.lastName =
      tempPayload.driver.lastName;
    payload.params[0].lobData.personalAuto.offerings[0].coverages.lineCoverages[1].terms[0].selectedValue =
      tempPayload.coverage.medicalPayment;
    payload.params[0].lobData.personalAuto.offerings[0].coverages.lineCoverages[4].terms[0].selectedValue =
      tempPayload.coverage.uMID;
    payload.params[0].baseData.policyAddress =
      payload.params[0].baseData.accountHolder.primaryAddress;

    setPayload(payload);
  };

  function isValueEmpty(value) {
    if (value === null || value === "" || value === " ") return true;
    //  if (Array.isArray(value)) return value.some(isValueEmpty);
    if (typeof value === "object" && value !== null) return isInputValid(value);
    return false;
  }

  function isInputValid(jsonObj) {
    for (let key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        const value = jsonObj[key];
        if (key != "sSN" && isValueEmpty(value)) {
          setIsFormValid(false);
          return true;
        }
      }
    }
    return false;
  }

  const makeRpcCall = async (canCall) => {
    updateJson();
    setIsButtonClicked(true);
    const isHavingBlankInput = isInputValid(tempPayload);

    if (!isHavingBlankInput) {
      setisLoading(true);
      const endpoint = process.env.REACT_APP_API_URL;
      const method = "createHVQ";
      const params = payload.params;

      const data = await JsonRpcClient(endpoint, method, params);
      setshowZip(false);
      setisLoading(false);
      setMedicalPayment(
        data.lobData.personalAuto.offerings[0].coverages.lineCoverages
          .find((i) => i.publicID == "PAMedPayCov")
          .terms.find((i) => i.publicID == "PAMedLimit").chosenTermValue
      );
      setUIMD(
        data.lobData.personalAuto.offerings[0].coverages.lineCoverages
          .find((i) => i.publicID == "PAUIMPDCov")
          .terms.find((i) => i.publicID == "PAUIMPDlimit").chosenTermValue
      );
      setPremium(data.premium);
      setQuoteID(data.quoteID);
      setQuoteKey(data.quoteKey.policyQuoteID);
    }
  };

  const makePurchaseCall = async (canCall) => {
    setisLoading(true);
    const endpoint = process.env.REACT_APP_API_URL_PURCHASE;
    const method = "purchasePolicy";
    const params = [
      {
        quoteKey: quoteKey,
      },
    ];

    const data = await JsonRpcClient(endpoint, method, params);
    setisLoading(false);
    if (data === null) setIsPurchaseFailed(true);

    setConfirmPopup();
  };

  const onValueChange = (load) => {};

  const setpopup = (canCall) => {
    updateJson();
    setIsButtonClicked(true);
    const isHavingBlankInput = isInputValid(tempPayload);
    if (!isHavingBlankInput) {
      setOpen(!open);
    }
  };

  const setConfirmPopup = () => {
    setConfirm(!confirm);
  };

  return (
    <>
      <div>
        <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <div
        className="App"
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}>
        <Header />
        <Container
          component="main"
          sx={{ mt: 8, mb: 2, boxShadow: 2, padding: 5 }}>
          {/* Verbiage */}
          <h3>
            EY's implementation includes full integration with all comparative
            rater systems.
          </h3>
          {/* Logos */}
          <div
            style={{ borderColor: "Red", border: "5px", marginLeft: "250px" }}>
            <MediaCard> </MediaCard>
          </div>
          {/* INforamtion intake */}
          <CategoryForm
            onChange={onValueChange}
            message={tempPayload}
            openPopUp={setpopup}
            callRPC={makeRpcCall}
            valid={isFormValid}
            clicked={isButtonClicked}></CategoryForm>
          {/* Boxes */}
          <div>
            <Button
              sx={{
                marginTop: 1.5,
                marginLeft: 1,
                gap: ".5erm",
                height: "3.5erm",
                right: ".5erm",
                top: "",
              }}
              // disabled={isFormValid}
              variant="contained"
              style={{ background: "lightcoral", paddingLeft: "10px" }}
              onClick={setpopup}>
              View App
            </Button>
            <Button
              sx={{
                marginTop: 1.5,
                marginLeft: 1,
                gap: ".5erm",
                height: "3.5erm",
                right: ".5erm",
                top: "",
              }}
              variant="contained"
              style={{ background: "lightcoral", paddingLeft: "10px" }}
              onClick={makeRpcCall}>
              Submit App
            </Button>
          </div>
          <BasicModal
            openbox={open}
            setpopup={setpopup}
            message={payload}></BasicModal>
          <CofirmationBox
            openbox={confirm}
            setConfirmPopUp={setConfirmPopup}
            isPurchaseFailed={isPurchaseFailed}
            message={payload}></CofirmationBox>
        </Container>
        {!isLoading && !showZip && (
          <Container
            component="main"
            sx={{ boxShadow: 2, display: "flex", direction: "row" }}>
            <div style={{ padding: "5px", width: "33%" }}>
              <Quote
                quoteID={quoteID}
                Premium={Premium}
                medicalPayment={medicalPayment}
                uMID={uMID}
                makePurchaseCall={makePurchaseCall}></Quote>
            </div>

            <div style={{ padding: "5px", width: "33%" }}>
              <Quote
                quoteID="0004133944"
                Premium={Premium}
                medicalPayment={medicalPayment}
                uMID="$3000"
                makePurchaseCall={makePurchaseCall}></Quote>
            </div>

            <div style={{ padding: "5px", width: "33%" }}>
              <Quote
                quoteID="0004133945"
                Premium={Premium}
                medicalPayment={medicalPayment}
                uMID="$3000"
                makePurchaseCall={makePurchaseCall}></Quote>
            </div>
          </Container>
        )}

        <Footer />
      </div>
    </>
  );
}

export default App;
