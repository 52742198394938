import React, { useCallback, useEffect, useState } from "react";
import { Container, Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";

function Quote(props) {
  return (
    <Box
      sx={{
        boxShadow: 6,
        bgcolor: "#fff",
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        alignContent: "center",
        padding: "5px",
        width: "100%",
      }}>
      <div style={{ display: "flex" }}>
        <Box component="fieldset" sx={{ boxShadow: 6, width: "60%" }}>
          <legend style={{ fontWeight: "bold" }}>Quote Information</legend>
          <Box
            sx={{
              display: "flex",
              direction: "row",
            }}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: "15px" }}
              variant="h6">
              Quote number:
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", fontSize: "15px", color: "red" }}
              variant="h6">
              {props.quoteID}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", direction: "row" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "15px",
              }}
              variant="h6">
              Premium:
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", fontSize: "15px", color: "red" }}
              variant="h6">
              ${props.Premium}
            </Typography>
          </Box>
        </Box>
        <div style={{ verticalAlign: "center" }}>
          <Button
            sx={{
              marginTop: 1.5,
              marginLeft: 1,
              gap: ".5erm",
              height: "3.5erm",
              right: ".5erm",
              top: "",
              background: "red",
            }}
            // disabled={isFormValid}
            variant="contained"
            style={{ background: "lightcoral", paddingLeft: "10px" }}
            onClick={props.makePurchaseCall}>
            Buy Now
          </Button>
        </div>
      </div>
      <Box component="fieldset" sx={{ boxShadow: 6 }}>
        <legend style={{ fontWeight: "bold" }}>Coverage</legend>
        <Box sx={{ display: "flex", direction: "row" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "15px",
            }}
            variant="h6">
            Medical Payment Limit:
          </Typography>
          <Typography
            sx={{ fontWeight: "bold", fontSize: "15px", color: "red" }}
            variant="h6">
            ${props.medicalPayment}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", direction: "row" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "15px",
            }}
            variant="h6">
            UMotorist - Property Damage:
          </Typography>
          <Typography
            sx={{ fontWeight: "bold", fontSize: "15px", color: "red" }}
            variant="h6">
            {props.uMID}
          </Typography>
        </Box>
        {/* <Box sx={{ display: "flex", direction: "row" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "15px",
            }}
            variant="h6">
            Underinsured Motorist - Property Damage:
          </Typography>
          <Typography
            sx={{ fontWeight: "bold", fontSize: "15px", color: "red" }}
            variant="h6">
            {props.uMID}
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
}

export default Quote;
