import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function HorizontalMenu() {
  return (
    <AppBar position="static" color='white'>
      <Toolbar>
        <Typography align='left' style={{ textAlign:'bottom'}}  sx={{ flexGrow: 0.4 }}>
        <img  style={{ height:'90px'}} src='Grange.png'></img>
        </Typography>
        <Button style={{fontWeight:'bold', fontSize:'16px'}} color="inherit"  href="#home">Home</Button>
        <Button style={{fontWeight:'bold', fontSize:'16px'}} color="inherit" href="#about">Auto Insurance</Button>
        <Button style={{fontWeight:'bold', fontSize:'16px'}} color="inherit" href="#services">Home Insurance</Button>
        <Button style={{fontWeight:'bold', fontSize:'16px'}} color="inherit" href="#contact">Contact</Button>
      </Toolbar>
    </AppBar>
  );
}
